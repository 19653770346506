import { create } from 'zustand';
import { devtools } from 'zustand/middleware'

const imieiDatiStore = (set) => ({

  chiSonoData: null,
  setChiSonoData: (content) => {
    set({ chiSonoData: content });
  },

  leMieFotoData: null,
  setLeMieFotoData: (content) => {
    set({ leMieFotoData: content });
  },

  iMieiDatiPersonaliData: null,
  setIMieiDatiPersonaliData: (content) => {
    set({ iMieiDatiPersonaliData: content });
  },

  ilMioLuogoData:  {
    tipoluogo: "miaposizione",
    markerpos: [41.9028, 12.4964],
    center: [41.8719, 12.5674],
    zoom: 6,
    status: "init",
  },
  setIlMioLuogoData: (content) => {
    set({ ilMioLuogoData: content });
  },

  laMiaStanzaSegretaData: null,
  setLaMiaStanzaSegretaData: (content) => {
    set({ laMiaStanzaSegretaData: content });
  },
   
});

const useImieiDatiStore = create(devtools(imieiDatiStore));
export { useImieiDatiStore };