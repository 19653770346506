import { useUtentiChatStore } from '../../store';
import { Button } from 'primereact/button';
import React, { useEffect } from 'react';

const UserChatComp = (props) => {

    const { utenteChatCorrente, setUtenteChatCorrente } = useUtentiChatStore();

    useEffect(() => {
       
    }, []);

    const handleUserSelection  = (user) => {
        setUtenteChatCorrente(user)
    }

    return (
        <><div className="block font-bold text-center p-1 border-round mb-1">
            <Button disabled ={utenteChatCorrente.userId === props.user.userId ? true: false} onClick={(e) => {handleUserSelection(props.user)}} label={props.user.username} severity={props.user.online ? "success" : "danger"} text />
        </div></>
    );
}

export default UserChatComp;