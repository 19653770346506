import React from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const ConfirmDialogComp = ({ group }) => {
    return <ConfirmDialog group={group} />;
};

export const showConfirmDialog = (group, header, message, accept, reject, acceptLabel, rejectLabel) => {
    confirmDialog({
        group: group,
        header: header,
        message: message,
        accept: accept,
        reject: reject,
        acceptLabel: acceptLabel,
        rejectLabel: rejectLabel,
    });
};

export default ConfirmDialogComp;



