import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const iSuoiDatiStore = (set) => ({

  iSuoiDatiMainInfo:  {
  },
  setISuoiDatiMainInfo: (content) => {
    set({ iSuoiDatiMainInfo: content });
  },
});

const useISuoiDatiStore = create(devtools(iSuoiDatiStore));
export { useISuoiDatiStore };
