import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const suggestionsChiSonoStore = (set) => ({

  chiSonoSuggestions: {
    sesso: [],
    etnia: [],
    lingua:[],
  },
  setSuggestionsSesso: (suggestions) => set((state) => ({ 
    chiSonoSuggestions: { ...state.chiSonoSuggestions, sesso: suggestions } 
  })),
  setSuggestionsEtnia: (suggestions) => set((state) => ({ 
    chiSonoSuggestions: { ...state.chiSonoSuggestions, etnia: suggestions } 
  })),  
  setSuggestionsLingua: (suggestions) => set((state) => ({ 
    chiSonoSuggestions: { ...state.chiSonoSuggestions, lingua: suggestions } 
  })),  

});

const suggestionsLeMieFotoStore = (set) => ({

  leMieFotoSuggestions: {
    corporatura: [],
    aspetto: [],
    occhi: [],
    capelli: [],
    vesto: [],
    segniparticolari: [] ,
    interessi: [],
    mireputo: []
  },
  setSuggestionsCorporatura: (suggestions) => set((state) => ({ 
    leMieFotoSuggestions: { ...state.leMieFotoSuggestions, corporatura: suggestions } 
  })),
  setSuggestionsAspetto: (suggestions) => set((state) => ({ 
    leMieFotoSuggestions: { ...state.leMieFotoSuggestions, aspetto: suggestions } 
  })), 
  setSuggestionsOcchi: (suggestions) => set((state) => ({ 
    leMieFotoSuggestions: { ...state.leMieFotoSuggestions, occhi: suggestions } 
  })),  
  setSuggestionsCapelli: (suggestions) => set((state) => ({ 
    leMieFotoSuggestions: { ...state.leMieFotoSuggestions, capelli: suggestions } 
  })),  
  setSuggestionsVesto: (suggestions) => set((state) => ({ 
    leMieFotoSuggestions: { ...state.leMieFotoSuggestions, vesto: suggestions } 
  })),    
  setSuggestionsSegniparticolri: (suggestions) => set((state) => ({ 
    leMieFotoSuggestions: { ...state.leMieFotoSuggestions, segniparticolari: suggestions } 
  })),
  setSuggestionsInteressi: (suggestions) => set((state) => ({ 
    leMieFotoSuggestions: { ...state.leMieFotoSuggestions, interessi: suggestions } 
  })),     
  setSuggestionsMireputo: (suggestions) => set((state) => ({ 
    leMieFotoSuggestions: { ...state.leMieFotoSuggestions, mireputo: suggestions } 
  })),   
});

const suggestionsIMieiDatiPersonali = (set) => ({

  iMieiDatiPersonaliSuggestions: {
    sitsentimentale: [],
    figli: [],
    bevo: [],
    fumo: [],
    quiper: [],
    lavoro: [],
    siteconomica: [],
    vorreifigli: [],
  },
  setSuggestionsSitsentimentale: (suggestions) => set((state) => ({ 
    iMieiDatiPersonaliSuggestions: { ...state.iMieiDatiPersonaliSuggestions, sitsentimentale: suggestions } 
  })),
  setSuggestionsFigli: (suggestions) => set((state) => ({ 
    iMieiDatiPersonaliSuggestions: { ...state.iMieiDatiPersonaliSuggestions, figli: suggestions } 
  })),  
  setSuggestionsBevo: (suggestions) => set((state) => ({ 
    iMieiDatiPersonaliSuggestions: { ...state.iMieiDatiPersonaliSuggestions, bevo: suggestions } 
  })), 
  setSuggestionsFumo: (suggestions) => set((state) => ({ 
    iMieiDatiPersonaliSuggestions: { ...state.iMieiDatiPersonaliSuggestions, fumo: suggestions } 
  })),   
  setSuggestionsQuiper: (suggestions) => set((state) => ({ 
    iMieiDatiPersonaliSuggestions: { ...state.iMieiDatiPersonaliSuggestions, quiper: suggestions } 
  })),  
  setSuggestionsLavoro: (suggestions) => set((state) => ({ 
    iMieiDatiPersonaliSuggestions: { ...state.iMieiDatiPersonaliSuggestions, lavoro: suggestions } 
  })), 
  setSuggestionsSiteconomica: (suggestions) => set((state) => ({ 
    iMieiDatiPersonaliSuggestions: { ...state.iMieiDatiPersonaliSuggestions, siteconomica: suggestions } 
  })), 
  setSuggestionsVorreifigli: (suggestions) => set((state) => ({ 
    iMieiDatiPersonaliSuggestions: { ...state.iMieiDatiPersonaliSuggestions, vorreifigli: suggestions } 
  })), 

});

const suggestionsIlMioLuogo = (set) => ({

  ilMioLuogoSuggestions: {
    tipoluogo: [],
  },
  setSuggestionsTipoLuogo: (suggestions) => set((state) => ({ 
    ilMioLuogoSuggestions: { ...state.ilMioLuogoSuggestions, tipoluogo: suggestions } 
  })),

});


const useSuggestionsChiSonoStore = create(devtools(suggestionsChiSonoStore));
const useSuggestionsLeMieFotoStore = create(devtools(suggestionsLeMieFotoStore));
const useSuggestionsIMieiDatiPersonali = create(devtools(suggestionsIMieiDatiPersonali));
const useSuggestionsIlMioLuogo = create(devtools(suggestionsIlMioLuogo));
export { useSuggestionsChiSonoStore, useSuggestionsLeMieFotoStore, 
  useSuggestionsIMieiDatiPersonali, useSuggestionsIlMioLuogo };
