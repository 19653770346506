import { useUtentiChatStore } from '../../store';
import React, { useState, useEffect } from 'react';

const MessageLineComp = (props) => {

    const { utenteChatCorrente } = useUtentiChatStore();
    const [ owner, setOwner ] = useState()


    useEffect(() => {
        setOwner(props.content.userId === utenteChatCorrente.userId ? "other" : "you")
    }, []);

    return (
                
        <div className={`flex ${owner === 'you' ? 'justify-content-start' : 'justify-content-end'} flex-wrap`}>
            <div className="flex align-items-center justify-content-center p-2 bg-primary font-bold border-round m-2">{props.content?.message}</div>
        </div>           
        
    );
}

export default MessageLineComp;