import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './i18n/en/translation.json';
import translationIT from './i18n/it/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it', // lingua predefinita
    fallbackLng: 'en', // lingua di fallback
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
