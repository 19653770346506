import { create } from 'zustand';
import { devtools } from 'zustand/middleware';


const messaggiChatStore = (set) => ({
  messaggiChat: [],
  addMessaggioChat: (messaggio) => {
    set((state) => ({
      messaggiChat: [...state.messaggiChat, messaggio],
    }));
  },
});

const useMessaggiChatStore = create(devtools(messaggiChatStore));
export { useMessaggiChatStore };
