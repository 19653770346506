import { create } from 'zustand';
import { devtools } from 'zustand/middleware';


const spinnerStore = (set) => ({
  showSpinner: false,
  setShowSpinner: (value) => set({ showSpinner: value }),
});


const mainContentStore = (set) => ({
  currentContent: "ricerca",
  previusContent: null,
  setCurrentContent: (content) => {
    set({ currentContent: content });
  },
  setPreviusContent: (content) => {
    set({ previusContent: content });
  },  
});


const useSpinnerStore = create(devtools(spinnerStore));

const useMainContentStore = create(devtools(mainContentStore));

export { useSpinnerStore, useMainContentStore };