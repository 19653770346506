import '../assets/styles/components/LoginComp.less'
import React, { useState } from 'react';

import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

import MocLogo from '../assets/images/logo.png'
import { useUserStore } from '../store';

import CryptoJS from "crypto-js";
import useApiClientInterceptors  from '../useApiClientInterceptors';

const LoginComp = (props) => {

  const apiClient = useApiClientInterceptors();

  const { setIsLoggedIn, setColorBg, setColorText } = useUserStore();
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleChangeUsername = (val) => {
      setUsername(val);
  };

  const handleChangePassword = (val) => {
      setPassword(val);
  };  

  const generateSHA256 = (text) => {
    return CryptoJS.SHA256(text).toString();
  };

  const login = async () => {
    if (username && username.length > 0 /*&& password && password.length > 0*/) {
      let cryptedPassword = generateSHA256(password);
      try {
        const response = await apiClient.post(
          process.env.REACT_APP_API_USER_LOGIN,
          { username: username, password: cryptedPassword }
        );
        if (response.data && response.data.token) {
          localStorage.setItem('token', response.data.token);
          setIsLoggedIn(response.data.username,true);
          if (response.data.colorbg)
            setColorBg(response.data.colorbg)
          if (response.data.colortext) 
            setColorText(response.data.colortext)
          props.setToken(response.data.token)
        } else {
          
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    }
  };
  
  return (
    <>

    <div className="logo-background mt-4 flex flex-column py-5 gap-4">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img className='mt-4 logo-image' src={MocLogo} />
      </div>
      <div className="w-full px-4 elements-form  inline-flex flex-column gap-2 justify-content-center">
        <div className="inline-flex flex-column gap-2">
          <label htmlFor="username">Username</label>
          <InputText id="username" value={username} onChange={(e) => handleChangeUsername(e.target.value)} label="Username"></InputText>
        </div>
        <div className="inline-flex flex-column gap-2">
          <label htmlFor="username">Password </label>
          <InputText id="password" value={password} onChange={(e) => handleChangePassword(e.target.value)} label="Password" type="password"></InputText>
        </div>
        <div style={{ justifyContent: 'center' }} className="mt-4 flex">
          <Button className="w-full" label="Login" onClick={login} />
        </div>
      </div>
    </div>
    </>
  );
}

export default LoginComp;