import React, { useState } from 'react';
import './assets/styles/App.less';
import "primereact/resources/themes/saga-blue/theme.css"
import "primereact/resources/primereact.min.css"
import 'primeflex/primeflex.css';
import "primeicons/primeicons.css"

import { useUserStore } from './store';
import LoginComp from './components/LoginComp'
import MainComp from './components/MainComp'

function App() {

    const {user} = useUserStore();
    const [token, setToken] = useState(null);

    return (
        <div className="App">
            {user.isLoggedIn && token ? <MainComp token={token}/> : <LoginComp setToken={setToken}/>}
        </div>
    );
}

export default App;
