import '../../assets/styles/components/common/UserCardComp.less'
import React, { useEffect, useState} from 'react';
import { arrayBufferToBase64 } from "../../utils";
import { Image } from 'primereact/image';
import { useUtentiChatStore, useMainContentStore } from '../../store';
import { useISuoiDatiStore } from '../../store';
import { Button } from 'primereact/button'
import { Tag } from 'primereact/tag'
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';

const UserCardComp = (props) => {

    const { addUtenteChat, setUtenteChatCorrente } = useUtentiChatStore();
    const { setCurrentContent, setPreviusContent } = useMainContentStore();
    const { setISuoiDatiMainInfo } = useISuoiDatiStore();
    const [sourceImage, setSourceImage] = useState("");

    const handleUserChatSelection = (selectedUser) => {

        addUtenteChat(selectedUser)
        setUtenteChatCorrente(selectedUser)
        setCurrentContent("chat")
        setPreviusContent(null)

    }

    useEffect(() => {
        const base64String = arrayBufferToBase64(props.ute.immagine?.data);
        if (base64String){
            const byteArray = Uint8Array.from(atob(base64String), char => char.charCodeAt(0));
            const f = new File([byteArray], "", { type: props.ute.mimetype })
            setSourceImage(f)
        }
    }, [])

    const handleISuoiDatiSelection = () => {
        setCurrentContent("iSuoiDati")
        setPreviusContent("ricerca")
        setISuoiDatiMainInfo(props.ute)
    }

    return (
        <>
            {props.layout === "ricerca" &&  props.status !== "loading"  && 
                <div style={{opacity:props.status === "dirty" ? 0.5: 1}} className="m-2 border-1 surface-border rounded">                  
                    <div>
                        <div onClick={handleISuoiDatiSelection} className={ sourceImage ? "avatar-ric" : "circle"} style={{backgroundColor:props.ute.colorbg, color:props.ute.colortext}}>
                            {sourceImage ? <Image src={URL.createObjectURL(sourceImage)} /> : <span>{props.ute.username[0].toUpperCase()}</span> }
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }} className="username mt-2"><Tag value={props.ute.username} severity={props.ute.online ? "success" : "danger"}></Tag></div>
                        <div style={{ display: 'flex', justifyContent: 'center' }} className="chat-btn my-2"><Button className="p-button-rounded" onClick={(e) => {handleUserChatSelection(props.ute)}} >chat</Button></div>
                    </div>
                 </div>   
            }
            {props.layout === "ricerca" &&  props.status === "loading" &&
            <div className="m-3">
                    <Skeleton shape="circle" size="5rem" className="mr-2"></Skeleton>
                    <Skeleton width="80%" className="m-2"></Skeleton>
                    <Skeleton width="80%" className="m-2"></Skeleton>
            </div>
            }

            {props.layout === "lista" &&   
                <Card>
                    <div className="grid-container-lista">
                        <div className="avatar">avatar</div>
                        <div className="username"><Tag value={props.ute.username} severity={props.ute.online ? "success" : "danger"}></Tag></div>
                    </div>
                </Card>
            }  
        </>
    );
}

export default UserCardComp;