import '../assets/styles/components/MainComp.less'
import io from 'socket.io-client';
import { Button } from 'primereact/button'
import { useUserStore, useSpinnerStore, useUtentiRicercaStore, useUtentiChatStore, 
  useMessaggiChatStore, useMainContentStore } from '../store';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Avatar} from 'primereact/avatar'
import  RicercaComp  from './ricerca/RicercaComp'
import ISuoiDatiComp from './isuoidati/ISuoiDatiComp';
import  ChatComp  from './chat/ChatComp'
import IMieiDatiComp from './imieidati/IMieiDatiComp'
import BadgeComp from './BadgeComp';
import FooterComp from './FooterComp';
import Spinner from './Spinner';
import useApiClientInterceptors  from '../useApiClientInterceptors';
import localforage from 'localforage';

let socket = null;

const MainComp = () => {

    const { t } = useTranslation();
    const apiClient = useApiClientInterceptors();
    const showSpinner = useSpinnerStore((state) => state.showSpinner);
    const { addMessaggioChat } = useMessaggiChatStore(); 
    const { currentContent, previusContent, setCurrentContent, setPreviusContent} = useMainContentStore();
    const { setUtenteOnlineRicerca  } = useUtentiRicercaStore()
    const { setUtenteOnlineChat } = useUtentiChatStore()
    const { user, setColorBg, setColorText  } = useUserStore();
    const [showBadge, setShowBadge] = useState(true);
    const [avatarURL, setAvatarURL] = useState(null);

    localforage.config({
      driver: localforage.INDEXEDDB, // Utilizza IndexedDB come preferenza
      name: 'MOC',
    });
    

    const endMenu = (
      <div className="flex align-items-center gap-2">
          <InputText placeholder="Search" type="text" className="w-auto" />
          { showBadge ? <BadgeComp className="hidden md:block" value={user.username[0].toUpperCase()} colortext={user.colortext} colorbg={user.colorbg} />
          :<Avatar className="hidden md:block" image={avatarURL}  shape="circle" />}
      </div>
    );



    const itemsMenu = [
        {
          label:   t('ricerca'),
          command: () => {
            setCurrentContent("ricerca")
            setPreviusContent(null)
          },
        }, 
        {
          label:   t('imieidati'),
          command: () => {
            setCurrentContent("imieidati")
            setPreviusContent(null)
          }
        },
        {
          label:  t('lemieliste'),
          items: [
            {
                label: t('preferiti'),
                command: () => {
                }
            },
            {
                label: t('amici'),
                command: () => {
                }
            },
            {
                label: t('LaMiaStanzaSegreta'),
                command: () => {
                }
            },
            {
              label: t('listanera'),
              command: () => {
              }
          }
          ]
        }
    ];  

    
    useEffect(() => {

        const token = localStorage.getItem('token');
        
        /*socket = io(process.env.REACT_APP_BE_BASE_URL, {
             withCredentials: true,
        });*/

        socket = io(process.env.REACT_APP_BE_BASE_URL, {
          extraHeaders: {
            Authorization: `Bearer ${token}`
          }
        });

        const handleReceiveMessage = (payload) => {
            const msgData = payload.msgData
            if (msgData.type === "chat"){
                addMessaggioChat(msgData)
            } 
        }    

        const handleUserJoined = (userId) => {
            setUtenteOnlineRicerca(userId,true)
            setUtenteOnlineChat(userId,true)
            console.log('User joined:', userId);
        };

        const handleUserLeft = (userId) => {
          setUtenteOnlineRicerca(userId,false)
          setUtenteOnlineChat(userId,false)
            console.log('User left:', userId);
        };

        socket.on('connect', () => {
            console.log('Connected to the server');
            socket.emit('join', { });
        });

        socket.on('receiveMessage', handleReceiveMessage);
        socket.on('userJoined', handleUserJoined);
        socket.on('userLeft', handleUserLeft);

        socket.on('disconnect', () => {
            console.log('Disconnected from the server');
        });
        
        /*const unsubscribeNuoviUtentiChat = useUtentiChatStore.subscribe(
            (state) => state.utentiChat,
            (utentiChat, previousUtentiChat) => {
              if (utentiChat.length > previousUtentiChat.length) {
                const newUsers = utentiChat.filter(
                  user => !previousUtentiChat.some(prevUser => prevUser.userId === user.userId)
                );
                newUsers.forEach(newUser => {
                  console.log('New user added:', newUser);
                });
              }
            }
          ); */  
          
          const payload = { };
          const avatar = async () => {
              try {
                  const response = await apiClient.post(
                  process.env.REACT_APP_API_USER_AVATAR,
                  { payload },  
              );
              if (response.data ) {
                  if (response.data.avatar){
                    const bufferData = response.data.avatar.data
                    const uint8Array = new Uint8Array(bufferData);
                    const blob = new Blob([uint8Array], { type: response.data.mimetype});
                    const imageUrl = URL.createObjectURL(blob);
                    setShowBadge(false)
                    setAvatarURL(imageUrl)
                  } else {
                    setShowBadge(true)
                  }
              } else {
                  
              }
              } catch (error) {
                  console.log("Error fetching data:", error);
              }
          }; 
      
          avatar()


        // Cleanup on unmount or when token/user.username changes
        return () => {
            //unsubscribeNuoviUtentiChat();
            //localStorage.removeItem('token');
            socket.off('receiveMessage', handleReceiveMessage);
            socket.off('userJoined', handleUserJoined);
            socket.off('userLeft', handleUserLeft);
            socket.disconnect();
            localforage.removeItem("avatar")
            localforage.removeItem("foto1")
            localforage.removeItem("foto2")
            localforage.removeItem("foto3")
        };
    }, []); 
    
    
    useEffect(() => {
      setColorText(user.colortext)
      setColorBg(user.colorbg)
    }, [user.colortext, user.colorbg]); 

    const renderContent = () => {
        switch (currentContent) {
          case 'ricerca':
            return <RicercaComp />;
          case 'iSuoiDati':
            return <ISuoiDatiComp />;
          case 'chat':
            return <ChatComp socket={socket} />;
          case 'imieidati':
            return <IMieiDatiComp />;            
          default:
            return <div></div>;
        }
    };

    const goBack = () => {
      setCurrentContent(previusContent)
      setPreviusContent(null)
    }


    return (
        <>
          <Menubar model={itemsMenu} end={endMenu} />
          {renderContent()}
          <Spinner visible={showSpinner} />
          <FooterComp></FooterComp>
          {previusContent && <Button
            onClick={goBack}
            icon="pi pi-arrow-left"
            className="p-button-rounded p-button-primary floating-back-button"
            aria-label="Add"
          />}
        </>
    );
}

export default MainComp;