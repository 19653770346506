
import React from 'react';

const BadgeComp = (props) => {
  return (
    <>
    <div style = {{
        width: "2.4em",
        height: "2.4em",
        backgroundColor:props.colorbg,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: props.colortext,
        fontWeight: "bold"
    }}>{props.value}
    </div>
    </>
  );
}

export default BadgeComp;