import { useEffect, useState } from 'react';
import { DataView  } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { useUtentiRicercaStore, useFiltriRicercaStore } from '../../store'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { aggiungiLabel } from '../../utils'
import { useSuggestionsChiSonoStore } from '../../store';
import { Badge } from 'primereact/badge';
import { useTranslation } from 'react-i18next';
import MocFieldComp from '../common/MocFieldComp';
import  UserCardComp  from '../common/UserCardComp'
import useApiClientInterceptors  from '../../useApiClientInterceptors';

const RicercaComp = () => {

    const { chiSonoSuggestions, setSuggestionsSesso, setSuggestionsEtnia, setSuggestionsLingua }  = useSuggestionsChiSonoStore()
    const { utentiRicerca, setUtentiRicerca } = useUtentiRicercaStore();
    const { filtriRicerca, setFiltriRicerca } = useFiltriRicercaStore()
    const apiClient = useApiClientInterceptors();
    const apiClientNoSpinner = useApiClientInterceptors(true);
    const { t } = useTranslation();
    const [numeroRisultati, setNumeroRisultati] = useState(null);
    const [reset, setReset] = useState(false);
    const [status, setStatus] = useState("none");

    useEffect(() => {  
        
        const manageChiSonoSuggestions = () => {

            if (chiSonoSuggestions?.sesso?.length === 0){
    
                const fetchSuggestions = async () => {
                    try {
                        const response = await apiClient.get(
                        process.env.REACT_APP_API_SUGGESTIONS_CHISONO);
                        if (response.data) {
                            setSuggestionsSesso(aggiungiLabel(response.data.sesso,t))
                            setSuggestionsEtnia(aggiungiLabel(response.data.etnia,t))
                            setSuggestionsLingua(aggiungiLabel(response.data.lingua,t))
                        } else {
                        
                        }
                    } catch (error) {
                        console.log("Error fetching data:", error);
                    }
                };
                fetchSuggestions();
            }
        }
        manageChiSonoSuggestions()      
        ricerca()
    }, [])

    const ricercaCounter = async () => {
        console.log("ricercaCounter")
        try {
            const response = await apiClientNoSpinner.post(
            process.env.REACT_APP_API_USERS_RICERCA_COUNT,
            { filtriRicerca },  
        );
        if (response?.data) {
            setNumeroRisultati(response.data[0].totalCount)
        } else {
            
        }
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };  

    
    const ricerca = async () => {
        setStatus("loading")
        try {
            const response = await apiClient.post(
            process.env.REACT_APP_API_USERS_RICERCA,
            { filtriRicerca },  
        );

        setTimeout(() => {
            setStatus("none")
        }, 200); 

        if (response.data && response.data.length > 0) {
            setUtentiRicerca(response.data)
        } else {
            setUtentiRicerca([])
        }
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    }; 

    useEffect(() => {  

        console.log("filtriRicerca:",filtriRicerca)
        setReset(false)
        ricercaCounter()
        
    }, [filtriRicerca])
    
    const updateFiltro = (fieldData) => {
        
        setStatus("dirty")
        if (!fieldData.newValue){

            const { [fieldData.campo]: _, ...newObject } = filtriRicerca;
            setFiltriRicerca(newObject);
            
        } else {
            setFiltriRicerca({...filtriRicerca, [fieldData.campo]:fieldData})
        }
    
    }


    const itemTemplate = (ute, index) => {
        if (!ute) {
            return;
        }
        return (<UserCardComp layout="ricerca" ute={ute} status={status}/>)
    };  
    
    const resetFiltri = () => {
        setStatus("dirty")
        setReset(true)
        setFiltriRicerca({})
    }
    
    const listTemplate = (utentiRicerca, layout) => {
        return (
            <>
                <div>
                    <Accordion activeIndex={0}>
                        <AccordionTab
                            header={
                                <span className="flex align-items-center gap-2 w-full">
                                    <span className="font-bold white-space-nowrap">{t('filtri')}</span>
                                    { Object?.keys(filtriRicerca).length > 0 && <Badge value={Object?.keys(filtriRicerca).length} className="ml-auto" />}
                                </span>
                            }
                        >

                        <div className="m-2 grid">
                            <div className="col-4">
                                <MocFieldComp 
                                    id="sessosono" 
                                    field="label" 
                                    value={reset? "" : filtriRicerca?.chisono?.sessosono} 
                                    suggestions={chiSonoSuggestions?.sesso} 
                                    sezione="chisono"
                                    type="autocomplete"
                                    updateField={updateFiltro}
                                    label={t('sessocerco')}
                                />                      
                            </div>
                            <div className="col-4">
                                <MocFieldComp 
                                    id="sessomipiace" 
                                    field="label" 
                                    value={reset? "" : filtriRicerca?.chisono?.sessomipiace}
                                    suggestions={chiSonoSuggestions?.sesso} 
                                    sezione="chisono"
                                    type="autocomplete"
                                    updateField={updateFiltro}
                                    label={t('acuipiace')}
                                />                      
                            </div>

                        </div>

                        <div class="flex flex-row-reverse flex-wrap">
                            <Button icon="pi pi-times" label={t('reset')} severity="danger" rounded onClick={resetFiltri}/>
                            <Button disabled={status !== "dirty" || numeroRisultati == 0} className="mx-1" icon="pi pi-search" label={t('ricerca')} severity="success" rounded onClick={ricerca}/>
                        </div>

                        </AccordionTab>
                    </Accordion>
                </div>

                <div className="col-12" style={{textAlign:"center"}}>{t('risultati')} {numeroRisultati}</div>

                <div className="grid grid-nogutter">
                    {utentiRicerca.map((ute, index) => (
                        <div key={ute.userId}> 
                            {itemTemplate(ute, index)}
                        </div>
                    ))}
                </div>
                </>
                );
            };

    return (
        <>
         <DataView value={utentiRicerca} listTemplate={listTemplate} layout={"grid"} />
        </>
    );
}

export default RicercaComp;