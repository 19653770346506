
export const aggiungiLabel = (inputObj, t) => {
    
    const noDropdownFields = ["eta","messaggio","statomessaggio","colorbg",
        "colortext","alto","peso", "markerpos", "center","zoom"]
    
    if (Array.isArray(inputObj)) {
        return inputObj.map(obj => {
            return {
                ...obj,
                label: t(obj.id)
            };
        });
    } else {
        const outputObj = {};
        for (const key in inputObj) {
            if (noDropdownFields.includes(key)) {
                outputObj[key] = inputObj[key]
            } else {
                if (inputObj.hasOwnProperty(key)) {
                    if (Array.isArray(inputObj[key])){
                        outputObj[key] =  inputObj[key].map(k => {
                            return {
                                id:k,
                                label: t(k)
                            };
                        });
                    } else {
                        outputObj[key] = {
                            id: inputObj[key],
                            label: t(inputObj[key])
                        };
                    }   
                }
            }
        }
        return outputObj;
    }
}  


export const aggiungiLabelInteressi = (inputObj,t) => {
    const outputObj = [];
    inputObj.map(obj => {
        const newObj = {}
        newObj['id'] = obj['id']
        newObj['label'] = t(obj['id'])
        const newItems = []
        obj['items'].map(objArr => {
            let newObjArr = objArr
            newObjArr['label'] = t(objArr['value'])
            newItems.push(newObjArr)
        });
        newObj['items'] = newItems
        outputObj.push(newObj)
    });
    return outputObj
};

