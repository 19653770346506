import React, { useEffect, useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { arrayBufferToBase64 } from "../../utils";
import { Image } from 'primereact/image';
import { ColorPicker } from 'primereact/colorpicker';
import useApiClientInterceptors  from '../../useApiClientInterceptors';
import { useTranslation } from 'react-i18next';
import ConfirmDialogComp, { showConfirmDialog } from '../ConfirmDialogComp';
import { Button } from 'primereact/button';
import localforage from 'localforage';

const UploadImageComp = (props) => {

    const { t } = useTranslation();
    const [uploadedImage, setUploadedImage] = useState(null);
    const apiClient = useApiClientInterceptors();
    const fileUploadRef = useRef(null);
    const immagineAttuale = useRef(null);
    const [immagineAnteprima, setImmagineAnteprima] = useState(null);
    const keyImg = `${props.tipoImmagine}`
    const preview = props.preview ? true : false
    const [editStatus, setEditStatus] = useState(false);
    const defaultBgCol = "#f5f5f5"
    const defaultTextCol = "#36444d"
    const [avatarBgCol, setAvatarBgCol] = useState(props.colorbg ||defaultBgCol);
    const [avatarTextCol, setAvatarTextCol] = useState(props.colortext || defaultTextCol);
    const [statoImmagine, setStatoImmagine] = useState(null);

    useEffect(() => {
        getImage(keyImg).then(imageData => {
            if (imageData === null){
                fetchImg();  
            } else {
                if (imageData.base64String !== "NO-IMAGE") { 
                    displayImage(imageData.base64String,imageData.mimeType)
                }
            }
        });
    }, []); 

    useEffect(() => {
        setAvatarBgCol(props.colorbg)
    }, [props.colorbg]); 

    useEffect(() => {
        setAvatarTextCol(props.colortext)
    }, [props.colortext])
    
    const fetchImg = async () => {
        try {
            const response = await apiClient.post(process.env.REACT_APP_API_IMAGES_DOWNLOADIMAGE, {tipoImmagine:props.tipoImmagine});
            if (response.status === 200) {
                const file = response.data
                if (file === "NO-IMAGE"){
                    saveImage(keyImg, "NO-IMAGE")
                } else {
                    const base64String = arrayBufferToBase64(file.filedata.data);
                    displayImage(base64String,file.mimetype)
                    setStatoImmagine(file.stato)
                    saveImage(keyImg, base64String, file.mimetype, file.stato)
                }
            } else {
                console.log('Error fetching files');
            }
        } catch (error) {
            console.log('Error fetching files:', error);
        }
    };    


    const displayImage = (base64String,mimeType) => {
        const byteArray = Uint8Array.from(atob(base64String), char => char.charCodeAt(0));
        const f = new File([byteArray], "", { type: mimeType })
        setUploadedImage(f)
    }

    const saveImage = async (key, base64String, mimeType, stato) => {
        const imageData = { base64String, mimeType, stato };
        return localforage.setItem(key, imageData)
            .then(() => {
            console.log('Image saved successfully!');
            })
            .catch(err => {
            console.log('Error saving image:', err);
            });
    };  

    const getImage = async key => {
        return localforage.getItem(key)
            .then(imageData => {
            if (imageData) {
                console.log('Retrieved Image Data:', imageData);
                setStatoImmagine(imageData.stato)
                return imageData;
            } else {
                console.log('No image found');
                return null;
            }
            })
            .catch(err => {
            console.log('Error retrieving image:', err);
            });
    };

    const cancelImg = async () => {
        localforage.removeItem(keyImg)
        setUploadedImage(null)
        try {
            const response = await apiClient.post(process.env.REACT_APP_API_IMAGES_CANCELIMAGE, {tipoImmagine:props.tipoImmagine});
            if (response.status === 200) {
            } else {
                console.log('Error deleting files');
            }
        } catch (error) {
            console.log('Error deleting files:', error);
        }
    }; 

    const uploadHandler = async ({ files }) => {

        if (uploadedImage) {
            uploadConfirmDialog({ files })
        } else {
            confirmUpload({ files })
        }
       
    };
    
    const confirmUpload = async ({ files }) => {

        if (uploadedImage) {
            console.log("file già presente, sostituire? il file dovrà essere approvato1")
        }
        
        const formData = new FormData();         
        formData.append('tipoImmagine', props.tipoImmagine); 
        formData.append('stato', 'inserito');  
        for (const file of files) {
            formData.append('files', file); 
        }
        try {
            const response = await apiClient.post(process.env.REACT_APP_API_IMAGES_UPLOADIMAGE, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Assicurati di impostare il tipo di contenuto correttamente
                },
            } );
            if (response.status === 200) {
                console.log('Upload successful');
                console.log("Il file dovrà essere approvato da un amministratore")
                fetchImg()                
                setEditStatus(false)
                setImmagineAnteprima(null)
                fileUploadRef?.current?.clear();
            } else {
                console.log('Upload failed');
            }
        } catch (error) {
            console.log('Error uploading file', error);
        }
    };     

    const onSelectHandler = (event) => {
        if (uploadedImage) {
            console.log("file già presente, sostituire? il file dovrà essere approvato1")
        }
        const file = event.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImmagineAnteprima(reader.result);
          };
          reader.readAsDataURL(file);
        }
        
    }

    const dialogUploadMessage = (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
            <i className="pi pi-exclamation-circle text-6xl text-primary-500"></i>
            <span>{t('confermaUploadMsg')}</span>
        </div>
    );

    const dialogCancelMessage = (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
            <i className="pi pi-exclamation-circle text-6xl text-red-500"></i>
            <span>{t('confermaCancelMsg')}</span>
        </div>
    );

    const acceptUpload = ({ files }) => {
        confirmUpload({ files })
    };

    const acceptCancel = () => {
        cancelImg()
    };

    const reject = () => {
    };


    const uploadConfirmDialog = ({files}) => { 
        showConfirmDialog(
            'templating',
            t('confermaUploadTit'),
            dialogUploadMessage,
            () => acceptUpload({files}),  
            reject,
            t('conferma'),
            t('annulla'),
        );
    };

    const cancelConfirmDialog = () => {  
        showConfirmDialog(
            'templating',
            t('confermaCancelTit'),
            dialogCancelMessage,
            () => acceptCancel(), 
            reject,
            t('conferma'),
            t('annulla'),
        );
    };    

    const onCancelHadler = () => {
        cancelConfirmDialog()
    }

    const onEditHadler = () => {
        setEditStatus(!editStatus)
    }

    const onRemoveHandler = () => {
        setImmagineAnteprima(null)
    }
    
    const setBgColor = (col) => {
       console.log(col)
       setAvatarBgCol(col)
    }

    const setTextColor = (col) => {
        console.log(col)
        setAvatarTextCol(col)
    }

    const onHideBgColPick = () => {
        try {
            const fieldData = {sezione: "chisono", type:"text", campo:"colorbg", newValue:avatarBgCol }
            props.updateField(fieldData);
            props.updataMainBadge(avatarTextCol,avatarBgCol)
        } catch (err) {
        }
    }
    const onHideTextColPick = () => {
        try {
            const fieldData = {sezione: "chisono", type:"text", campo:"colortext", newValue:avatarTextCol }
            props.updateField(fieldData);
            props.updataMainBadge(avatarTextCol,avatarBgCol)
        } catch (err) {
        }
    }


    return (
        <div className="container">
            <div className="row justify-content-center text-center">{props.titoloImmagine}</div>
            { uploadedImage != undefined ?
             <div ref={immagineAttuale} className={`row mt-2 justify-content-center ${props.imageClassName}`}>
                    <div key={props.tipoImmagine} >
                        <Image preview={preview} ata-pr-tooltip={t('immagineInApprovazione')} src={URL.createObjectURL(uploadedImage)} />
                    </div>
            </div> :
            <div className="row mt-2 justify-content-center text-center">
                {props.noImageMode === 'icon' ?<i className={`pi pi-image p-5 ${props.noImgaeClassName}`}></i>
                : <div style={{backgroundColor: avatarBgCol}} className={`p-5 avatar-container-text`}><span style={{color: avatarTextCol}} className='centered-letter'>{props.letter}</span></div>}
            </div>}
            {statoImmagine === 'inserito' && <div className='statoimmagine row text-center'>In approvazione</div>}
            <div className="row">
                <div className="col-12 text-center"><Button onClick={onEditHadler} rounded icon="pi pi-pencil" text />
                        {uploadedImage &&<Button onClick={onCancelHadler} rounded icon="pi pi-times" text severity="danger" aria-label="Cancel" />}
                        {!uploadedImage && props.noImageMode === 'text' && 
                        <div>
                            <ColorPicker 
                                value={avatarBgCol} 
                                onChange={(e) => setBgColor("#"+e.value)}
                                onHide={onHideBgColPick}
                            />
                            <ColorPicker 
                                value={avatarTextCol} 
                                onChange={(e) => setTextColor("#"+e.value)}
                                onHide={onHideTextColPick}
                            />
                         </div>}
                </div>
                { editStatus && <div className="col-12 bg-secondary text-white text-center">
                    <div>
                        <FileUpload ref={fileUploadRef}
                                    name="uploadimage" 
                                    accept="image/*" 
                                    maxFileSize={1000000} 
                                    withCredentials
                                    multiple
                                    emptyTemplate={<p className="m-0">{t('Drag&DropFile')}</p>} 
                                    customUpload
                                    onSelect={onSelectHandler}
                                    onDrag={onSelectHandler}
                                    uploadHandler={uploadHandler}
                                    onRemove={onRemoveHandler}
                                    files = {uploadedImage}
                                    chooseLabel={t('ChooseLabel')}
                                    uploadLabel={t('UploadLabel')} 
                                    cancelLabel={t('CancelLabel')}/>
                        </div>
                </div>}
            </div>
            { immagineAnteprima && <div className="row justify-content-center">
                <div className="text-center ">
                        <img src={immagineAnteprima}  />
                </div>
            </div> }

            <ConfirmDialogComp
                group="templating"
            />
        </div>
    )
}

export default UploadImageComp;