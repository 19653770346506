import '../../assets/styles/components/isuoidati/ISuoiDatiComp.less'
import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { arrayBufferToBase64 } from "../../utils";
import { Image } from 'primereact/image';
import { aggiungiLabel } from '../../utils'
import { useISuoiDatiStore } from '../../store';
import { useTranslation } from 'react-i18next';
import useApiClientInterceptors  from '../../useApiClientInterceptors';

const ISuoiDatiComp = () => {

    const { iSuoiDatiMainInfo} = useISuoiDatiStore();
    const { t } = useTranslation();
    const apiClient = useApiClientInterceptors();
    const [sourceImage, setSourceImage] = useState("");
    const userLetter = iSuoiDatiMainInfo.username.charAt(0).toUpperCase()
    const [chiEData, setChiEData] = useState();

    useEffect(() => {
        console.log("beppe",iSuoiDatiMainInfo)
        const base64String = arrayBufferToBase64(iSuoiDatiMainInfo.immagine?.data);
        if (base64String){
            const byteArray = Uint8Array.from(atob(base64String), char => char.charCodeAt(0));
            const f = new File([byteArray], "", { type:iSuoiDatiMainInfo.mimetype })
            setSourceImage(f)
        }
    }, [])   

    const ChiEComp = () => {

        return (
            <div className={ sourceImage ? "avatar-isd" : "circle-isd"} style={{backgroundColor:iSuoiDatiMainInfo.colorbg, color:iSuoiDatiMainInfo.colortext}}>
                {sourceImage ? <Image src={URL.createObjectURL(sourceImage)} /> : <span>{userLetter}</span> }
            </div>
        );
      };

    return (
        <>
            <div>
                <div style = {{textAlign: "center"}}><p>{t('profiloutente')} {iSuoiDatiMainInfo.username}</p></div>
                <div>
                    <TabView>
                        <TabPanel header={t('ChiE')}>
                            <ChiEComp />
                        </TabPanel>
                        <TabPanel  header={t('LeSueFoto')}>
                            <p className="m-0">
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti 
                                quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in
                                culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. 
                                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                            </p>
                        </TabPanel>
                        <TabPanel  header={t('ISuoiDatiPersonali')}>
                            <p className="m-0">
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti 
                                quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in
                                culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. 
                                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                            </p>
                        </TabPanel>   
                        <TabPanel  header={t('ISuoiLuoghi')}>
                            <p className="m-0">
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti 
                                quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in
                                culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. 
                                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                            </p>
                        </TabPanel>        
                        <TabPanel  header={t('LaSuaStanzaSegreta')}>
                            <p className="m-0">
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti 
                                quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in
                                culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. 
                                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                            </p>
                        </TabPanel> 
                        <TabPanel  header={`${t('Ioe')} ${iSuoiDatiMainInfo.username}`}>
                            <p className="m-0">
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti 
                                quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in
                                culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. 
                                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                            </p>
                        </TabPanel>                                                                                        
                    </TabView>
                </div>
            </div>
        </>
    );
}

export default ISuoiDatiComp;
