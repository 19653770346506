import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const utentiChatStore = (set) => ({
  utentiChat: [],
  utenteChatCorrente: null,
  
  setUtenteChatCorrente: (user) => {
    set((state) => ({
      ...state,
      utenteChatCorrente: {userId: user.userId, username: user.username }
    }));
  },
  
  addUtenteChat: (user) => {
    set((state) => {
      const esiste = state.utentiChat.some(u => u.userId === user.userId);
      if (!esiste) {
        return {
          ...state,
          utentiChat: [...state.utentiChat, user],
        };
      }
      return state;
    });
  },
  
  setUtenteOnlineChat: (userId, isOnline) => {
    set((state) => ({
      ...state,
      utentiChat: state.utentiChat.map((utente) => {
        if (utente.userId === userId) {
          return { ...utente, online: isOnline };
        }
        return utente;
      }),
    }));
  },
});

const useUtentiChatStore = create(devtools(utentiChatStore));
export { useUtentiChatStore };
