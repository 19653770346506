
import '../../assets/styles/components/common/MocFieldComp.less'
import React, { useEffect, useState} from 'react';
import { AutoComplete } from "primereact/autocomplete";
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from 'primereact/floatlabel';

const MocFieldComp = (props) => {

    const [value, setValue] = useState(null);
    const [filtered, setFiltered] = useState(null);
    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
        if (props.multiple && props.value?.id === null){
            setValue(null)
        } else {
            setValue(props.value)
        }
    }, [props.value])
    
    const search = (event) => {
        let _filtered;
    
        if (!event.query.trim().length) {
            _filtered = [...props.suggestions];
        }
        else {
            _filtered = props.suggestions.filter((item) => {
                return item.label.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
        setFiltered(_filtered);
    }

    const isLabelPresent = (labelVal) => {
        return props.suggestions.some(item => item.label === labelVal);
    };

    const handleOnChange = async (newValue) => {
      setValue(newValue);

      if (props.type === "autocomplete") {
        if (newValue.length === 0 || isLabelPresent(newValue.label)) {
          setIsInvalid(false);
          try {
            const fieldData = {
              sezione: props.sezione,
              type: props.type,
              campo: props.id,
              newValue: newValue,
            };
            props.updateField(fieldData);
          } catch (err) {}
        } else {
          if (!props.multiple)
            setIsInvalid(true);
        }
      } else if (props.type === "dropdown") {

        if (newValue) {
          try {
            const fieldData = {
              sezione: props.sezione,
              type: props.type,
              campo: props.id,
              newValue: newValue.id,
            };
            props.updateField(fieldData);
          } catch (err) {}
        }

      }
    };

    const handleOnChangeMult = async (newValue) => {
      console.log(newValue)
      try {
            const fieldData = {
                sezione: props.sezione,
                type: props.type,
                campo: props.id,
                newValue: newValue.map(item => item.id?.toString() || item.value?.toString() ),
            };
            props.updateField(fieldData,newValue);
      } catch (err) {
        console.log(err)
      }
    };    

    const handleOnBlur = async (newValue) => {
      console.log("handleOnBlur",newValue)
      if (props.type === 'inputnumber' || props.type === 'inputtextarea') {
        try {
            const fieldData = {sezione:props.sezione, type:props.type, campo:props.id, newValue:newValue }
            props.updateField(fieldData);
        } catch (err) {
        }
      }
    }

    return (
        <>
        { props.type === 'autocomplete' &&
          <FloatLabel>
              <AutoComplete 
                  className='full-width'
                  id={props.id} 
                  field={props.field} 
                  multiple={props.multiple} 
                  value={value} 
                  suggestions={filtered} 
                  optionGroupLabel={props.optionGroupLabel}
                  optionGroupChildren={props.optionGroupChildren}
                  groupedItemTemplate={props.groupedItemTemplate}
                  completeMethod={search} 
                  onChange={(e) => props.multiple ? handleOnChangeMult(e.value) : handleOnChange(e.value)} 
                  dropdown 
                  invalid={isInvalid} />
              <label htmlFor={props.id}>{props.label}</label>
          </FloatLabel>
        }{
          props.type === 'inputnumber' && 
          <FloatLabel>
              <InputNumber 
                  className='full-width'
                  id={props.id} 
                  maxLength={props.maxLength}
                  value={value} 
                  onValueChange={(e) => handleOnChange(e.value)} 
                  onBlur={(e) => handleOnBlur(e.target.value)}
                  showButtons={props.showButtons}
                  min={props.min}
                  max={props.max} />
              <label htmlFor={props.id}>{props.label}</label>
          </FloatLabel>
        }{
          props.type === 'inputtextarea' && 
          <FloatLabel>
            <InputTextarea 
                  className='full-width'
                  id={props.id} 
                  value={value || ""} 
                  onChange={(e) => setValue(e.target.value)}
                  onBlur={(e) => handleOnBlur(e.target.value)}
                  rows={props.rows} 
                  cols={props.cols} 
                  maxLength={props.maxLength}
              />      
              <label htmlFor={props.id}>{props.label}</label>
          </FloatLabel>
        }{
          props.type === 'dropdown' && 
          <FloatLabel className="w-full md:w-14rem">
            <Dropdown 
                  inputId={props.id} 
                  value={value} 
                  onChange={(e) => handleOnChange(e.value)} 
                  options={props.options} 
                  optionLabel={props.label}
                  className="w-full"
             />
            <label htmlFor={props.id}></label>
          </FloatLabel>
        }
        </>
    );
}

export default MocFieldComp;
