import '../../assets/styles/components/imieidati/ChiSonoComp.less'
import { aggiungiLabel } from '../../utils'
import React, { useEffect } from 'react';
import UploadImageComp from '../common/UploadImageComp';
import { useTranslation } from 'react-i18next';
import useApiClientInterceptors  from '../../useApiClientInterceptors';
import { showConfirmDialog } from '../ConfirmDialogComp';
import { useSuggestionsChiSonoStore } from '../../store';
import { useUserStore, useImieiDatiStore } from '../../store';
import MocFieldComp from '../common/MocFieldComp';

const ChiSonoComp = () => {
    
    const { t } = useTranslation();
    const apiClient = useApiClientInterceptors();
    const { chiSonoSuggestions, setSuggestionsSesso, setSuggestionsEtnia, setSuggestionsLingua }  = useSuggestionsChiSonoStore()
    const { chiSonoData, setChiSonoData }  = useImieiDatiStore()
    const { user, setColorBg, setColorText }  = useUserStore()
    const userLetter = user.username.charAt(0).toUpperCase()

    useEffect(() => {

        manageChiSonoSuggestions()
        manageChiSonoData()  
        
    }, []);

   
    const manageChiSonoSuggestions = () => {

        if (chiSonoSuggestions?.sesso?.length === 0){

            const fetchSuggestions = async () => {
                try {
                    const response = await apiClient.get(
                    process.env.REACT_APP_API_SUGGESTIONS_CHISONO);
                    if (response.data) {
                        setSuggestionsSesso(aggiungiLabel(response.data.sesso,t))
                        setSuggestionsEtnia(aggiungiLabel(response.data.etnia,t))
                        setSuggestionsLingua(aggiungiLabel(response.data.lingua,t))
                    } else {
                    
                    }
                } catch (error) {
                    console.log("Error fetching data:", error);
                }
            };
            fetchSuggestions();
        }
    }

    const dialogUpdateMessage = (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
            <i className="pi pi-exclamation-circle text-6xl text-primary-500"></i>
            <span>{t('confermaUpdateMess1')}</span>
        </div>
    );

    const manageChiSonoData = () => {
        
        if (!chiSonoData || Object.keys(chiSonoData)?.length === 0) {
        
            const fetchData = async () => {
                try {
                    const response = await apiClient.get(
                    process.env.REACT_APP_API_USER_CHISONODATA);

                    if (response.data?.length > 0) {
                        setChiSonoData(aggiungiLabel(response.data[0],t))
                    } else {
                    
                    }
                } catch (error) {
                    console.log("Error fetching data:", error);
                }
            };
            fetchData();
        }    

    }

    const reject = () => {
    };

    
    const handleUpdateMessaggio = async (fieldData) => {
        if (chiSonoData.messaggio !== fieldData.newValue){
            confirmUpdateField(fieldData)
        }
    }

    const confirmUpdateField = async (fieldData) => {
        showConfirmDialog(
            'templating',
            t('confermaUpdateMessTit'),
            dialogUpdateMessage,
            () => updateField(fieldData),  
            reject,
            t('conferma'),
            t('annulla'),
        );
    }

    const updateField = async (fieldData) => {
        try {
            const response = await apiClient.post(
                process.env.REACT_APP_API_USER_UPDATEFIELD, fieldData
            );
            if (response.data) {
                updateChiSonoData(fieldData.campo, fieldData.newValue) 
            }
        } catch (error) {
            console.log("Error updating data:", error);
            throw error; 
        }
    };
    

    const updateChiSonoData = (campo, valore) => {
        setChiSonoData({...chiSonoData, [campo]:valore})
    };


    const updataMainBadge = (colrortext, colorbg) => {
        setColorBg(colorbg)
        setColorText(colrortext)
    }

    
    return (
        <>
        <div className="formgrid grid">
            <div className="col-12 mb-4">
                <UploadImageComp   
                    tipoImmagine="avatar"
                    titoloImmagine={t('ImmagineAvatar')}
                    imageClassName="avatar-container" 
                    noImgaeClassName="avatar-noimage" 
                    updateField={updateField}
                    updataMainBadge={updataMainBadge}
                    colorbg={chiSonoData?.colorbg}
                    colortext={chiSonoData?.colortext}
                    noImageMode="text" 
                    letter = {userLetter}
                />
            </div>
            <div className="col-12 md:col-6 mb-4">
                <MocFieldComp 
                    id="sessosono" 
                    field="label" 
                    value={chiSonoData?.sessosono} 
                    suggestions={chiSonoSuggestions?.sesso} 
                    sezione="chisono"
                    type="autocomplete"
                    updateField={updateField}
                    label={t('sessosono')}
                />
            </div>
            <div className="col-12 md:col-6 mb-4">
                <MocFieldComp
                    id="eta" 
                    value={chiSonoData?.eta} 
                    mode="decimal" 
                    showButtons={true}
                    sezione="chisono"
                    type="inputnumber"
                    min={16} 
                    max={120}
                    label={t('eta')}
                    updateField={updateField}
                    maxLength={3}
                />
            </div>
            <div className="col-12 md:col-6 mb-4">
                <MocFieldComp 
                    id="sessomipiace" 
                    field="label" 
                    value={chiSonoData?.sessomipiace} 
                    suggestions={chiSonoSuggestions?.sesso} 
                    sezione="chisono"
                    type="autocomplete"
                    updateField={updateField}
                    label={t('sessomipiace')}
                />
            </div>
            <div className="col-12 md:col-6 mb-4">
                <MocFieldComp 
                    id="sessoacuipiace" 
                    field="label" 
                    value={chiSonoData?.sessoacuipiace} 
                    suggestions={chiSonoSuggestions?.sesso} 
                    sezione="chisono"
                    type="autocomplete"
                    updateField={updateField}
                    label={t('sessoacuipiace')}
                />
            </div>
            <div className="col-12 md:col-6 mb-4">
                <MocFieldComp 
                    id="etnia" 
                    field="label" 
                    value={chiSonoData?.etnia} 
                    suggestions={chiSonoSuggestions?.etnia} 
                    sezione="chisono"
                    type="autocomplete"
                    updateField={updateField}
                    label={t('Etnia')}
                />
            </div>
            <div className="col-12 md:col-6 mb-4">
                <MocFieldComp 
                    id="lingua" 
                    field="label" 
                    value={chiSonoData?.lingua} 
                    suggestions={chiSonoSuggestions?.lingua} 
                    sezione="chisono"
                    type="autocomplete"
                    updateField={updateField}
                    label={t('lingua')}
                />
            </div>  
            <div className="col-12 col-lg-12 mb-4">
                <MocFieldComp
                    id="messaggio" 
                    value={chiSonoData?.messaggio} 
                    sezione="chisono"
                    type="inputtextarea"
                    rows={3} 
                    cols={30} 
                    label={t('messaggio')}
                    updateField={handleUpdateMessaggio}
                    maxLength={500}
                />
            </div>
        </div>    
        </>
    );
}

export default ChiSonoComp;