import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const utentiRicercaStore = (set) => ({
  utentiRicerca: [],
  setUtentiRicerca: (nuovaListaUtenti) => set({ utentiRicerca: nuovaListaUtenti }),
  setUtenteOnline: (userId, isOnline) => {
      set(state => ({
          utentiRicerca: state.utentiRicerca.map(utente => {
              if (utente.userId === userId) {
                  return { ...utente, online: isOnline };
              }
              return utente;
          })
      }));
  },

  setUtenteOnlineRicerca: (userId, isOnline) => {
    set(state => ({
      utentiRicerca: state.utentiRicerca.map(utente => {
            if (utente.userId === userId) {
                return { ...utente, online: isOnline };
            }
            return utente;
        })
    }));
  },
});


const filtriRicercaStore = (set) => ({
    filtriRicerca: {},
    setFiltriRicerca: (content) => {
        set({ filtriRicerca: content });
    },
});


const useUtentiRicercaStore = create(devtools(utentiRicercaStore));
const useFiltriRicercaStore = create(devtools(filtriRicercaStore));
export { useUtentiRicercaStore, useFiltriRicercaStore };
