
import React from 'react';

const FooterComp = () => {
  return (
    <>
    </>
  );
}

export default FooterComp;
