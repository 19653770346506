import '../../assets/styles/components/chat/ChatComp.less'
import { useUserStore, useMessaggiChatStore, useUtentiChatStore } from '../../store';
import { useState, useEffect } from 'react';
import { Button } from 'primereact/button'
import { ScrollPanel } from 'primereact/scrollpanel'

import { InputTextarea } from 'primereact/inputtextarea'

import   MessageLineComp  from './MessageLineComp';
import   UserLineComp from './UserChatComp';


const ChatComp = (props) => {

    const [ message, setMessage] = useState("");
    const { user } = useUserStore();
    const { addMessaggioChat, messaggiChat } = useMessaggiChatStore();
    const { utentiChat, utenteChatCorrente } = useUtentiChatStore();
    const deltaTimeTyping = 1500
    const [recUserIsTyping, setRecUserIsTyping] = useState(false);
    const [isTimerActive, setIsTimerActive] = useState(false);

    useEffect(() => {

        props.socket.on('isUserTyping', handleIsRecUserTyping);

        return () => {
            props.socket.off('isUserTyping', null);
        }
       
    }, []);

    const handleImTyping = () => {
        const msgData = {recUserId: utenteChatCorrente.userId}
        props.socket?.emit('isUserTyping', msgData);
    }

    const handleIsRecUserTyping = (payload) => {
        const msgData = payload.msgData
        console.log("typing...", msgData)
        if(!recUserIsTyping){
            setRecUserIsTyping(true)
            setTimeout(() => {
                setRecUserIsTyping(false);  
              }, deltaTimeTyping);             
        }
    }

    const handleNewMessage = () => {
        const dataora = Math.floor(Date.now());
        const msgData = {type: "chat", dataora: dataora, recUserId: utenteChatCorrente.userId, message:message}
        props.socket?.emit('sendMessage', {...msgData});
        addMessaggioChat(msgData)
        setMessage('');
    }; 
    
    const handleKeyDown = () => {
        if (!isTimerActive) {  
            setIsTimerActive(true);  
      
            setTimeout(() => {
              console.log("faccio la chiamata...")
              handleImTyping()
              setIsTimerActive(false);  
            }, deltaTimeTyping);  
        }
    }

    return (
        <>
        <div className="grid">
            <div className="col-9">

                <div>
                    <div className=" m-2 ">

                        <div className="flex justify-content-between flex-wrap">
                            <div>{user.username}</div>
                            <div>{utenteChatCorrente.username}</div>
                        </div>

                        <ScrollPanel style={{ width: '100%', height: '25em' }}>
                        <div className="m-2">
                        {messaggiChat
                        .filter(msgData => (msgData?.userId === utenteChatCorrente.userId || msgData?.recUserId === utenteChatCorrente.userId))
                        .map((msgData) => (
                            <MessageLineComp 
                                content={msgData}
                                key={`${msgData.dataora}-${msgData.recUserId}`
                            }  />
                        ))}
                        </div>
                        { recUserIsTyping && <div className='istyping'>{utenteChatCorrente.username} is typing...</div>}
                        </ScrollPanel>
                    </div>
                    <div  className='m-2'>    
                        <InputTextarea onKeyDown={handleKeyDown} autoResize style={{ width: '100%' }} value={message} onChange={(e) => setMessage(e.target.value)} rows={5}  />
                    </div>
                    <div  className='m-2'>    
                        <Button label={"Invia"} onClick={(e) => handleNewMessage()}></Button>
                    </div>
                </div> 

            </div>
            <div className="col-3">
                {utentiChat.map((usrData) => (
                                <UserLineComp 
                                    user={usrData}
                                    key={`${usrData.userId}`
                                }  />
                            ))}
            </div>  
        </div>     
        </>
    );
}

export default ChatComp;