import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const userStore = (set) => ({
  user: {
    username: null,
    isLoggedIn: false,
    colorbg: "#f5f5f5",
    colortext : "#36444d"
    
  },
  setIsLoggedIn: (username, flag) => set((state) => ({ 
    user: { ...state.user, username: username, isLoggedIn: flag } 
  })),
  setColorBg: (color) => set((state) => ({ 
    user: { ...state.user, colorbg: color } 
  })), 
  setColorText: (color) => set((state) => ({ 
    user: { ...state.user, colortext: color } 
  })),   
});


const useUserStore = create(devtools(userStore));
export { useUserStore };
