
import '../../assets/styles/components/imieidati/LeMieFotoComp.less'
import { aggiungiLabel } from '../../utils'
import React, { useEffect, useState} from 'react';
import UploadImageComp from '../common/UploadImageComp';
import { useTranslation } from 'react-i18next';
import useApiClientInterceptors  from '../../useApiClientInterceptors';
import MocFieldComp from '../common/MocFieldComp';
import { useImieiDatiStore } from '../../store';

const LaMiaStanzaSegretaComp = () => {
    
    const { laMiaStanzaSegretaData, setLaMiaStanzaSegretaData }  = useImieiDatiStore()
    const { t } = useTranslation();
    const apiClient = useApiClientInterceptors();
    
    const immagini = [];
    for (let i = 1; i < 4; i++) {
        immagini.push(
            <div key={i} className="flex align-items-center flex-column mx-6">
                <UploadImageComp   
                    tipoImmagine={`fotoSegreta${i}`}
                    titoloImmagine={t(`fotoSegreta${i}`)}
                    imageClassName="foto-container" 
                    noImgaeClassName="foto-noimage"
                    noImageMode="icon" 
                    preview={true}
                />                    
            </div>
      );
    }

    useEffect(() => {

        manageLaMiaStanzaSegretaData()  
        
    }, []);

    const manageLaMiaStanzaSegretaData = () => {
        
        if (!laMiaStanzaSegretaData || Object.keys(laMiaStanzaSegretaData)?.length === 0) {
        
            const fetchData = async () => {
                try {
                    const response = await apiClient.get(
                    process.env.REACT_APP_API_USER_STANZASEGRETADATA);

                    if (response.data?.length > 0) {
                        setLaMiaStanzaSegretaData(aggiungiLabel(response.data[0],t))
                    } else {
                    
                    }
                } catch (error) {
                console.log("Error fetching data:", error);
                }
            };
            fetchData();
        }    

    }

    const updateField = async (fieldData) => {
        try {
            const response = await apiClient.post(
                process.env.REACT_APP_API_USER_UPDATEFIELD, fieldData
            );
            if (response.data) {
                updateLaMiaStanzaSegretaData(fieldData.campo, fieldData.newValue) 
            }
        } catch (error) {
            console.log("Error fetching data:", error);
            throw error; 
        }
    };

    const updateLaMiaStanzaSegretaData = (campo, valore) => {
        setLaMiaStanzaSegretaData({...laMiaStanzaSegretaData, [campo]:valore})
    };
    
    return (
        <>
            <div className="flex align-items-stretch flex-wrap" >
                {immagini}
            </div>
            <div className="formgrid grid">
                <div className="col-12 mb-4">
                    <MocFieldComp
                        id="messaggio" 
                        value={laMiaStanzaSegretaData?.messaggio} 
                        sezione="stanzasegreta"
                        type="inputtextarea"
                        rows={3} 
                        cols={30} 
                        label={t('messaggioSegreto')}
                        updateField={updateField}
                        maxLength={500}
                    />
                </div>
            </div>                                 
        </>
    );
}

export default LaMiaStanzaSegretaComp;