
import '../../assets/styles/components/imieidati/LeMieFotoComp.less'
import { aggiungiLabel, aggiungiLabelInteressi } from '../../utils'
import React, { useEffect, useState} from 'react';
import UploadImageComp from '../common/UploadImageComp';
import { useTranslation } from 'react-i18next';
import useApiClientInterceptors  from '../../useApiClientInterceptors';
import { useSuggestionsLeMieFotoStore } from '../../store';
import MocFieldComp from '../common/MocFieldComp';
import { useImieiDatiStore } from '../../store';

const LeMieFotoComp = () => {
    
    const { leMieFotoData, setLeMieFotoData }  = useImieiDatiStore()
    const { t } = useTranslation();
    const apiClient = useApiClientInterceptors();
    const { leMieFotoSuggestions, setSuggestionsCorporatura, setSuggestionsAspetto, setSuggestionsOcchi, setSuggestionsCapelli,
        setSuggestionsVesto, setSuggestionsSegniparticolri, setSuggestionsInteressi, setSuggestionsMireputo }  = useSuggestionsLeMieFotoStore()
    
    const immagini = [];
    for (let i = 1; i < 4; i++) {
        immagini.push(
            <div key={i} className="flex align-items-center flex-column mx-6">
                <UploadImageComp   
                    tipoImmagine={`foto${i}`}
                    titoloImmagine={t(`foto${i}`)}
                    imageClassName="foto-container" 
                    noImgaeClassName="foto-noimage"
                    noImageMode="icon" 
                    preview={true}
                />                    
        </div>
      );
    }

    useEffect(() => {

        manageLeMieFotoSuggestions()
        manageLeMieFotoData()  
        
    }, []);

    const manageLeMieFotoSuggestions = () => {


        if (leMieFotoSuggestions?.corporatura?.length === 0){


            const fetchSuggestions = async () => {
                try {
                    const response = await apiClient.get(
                    process.env.REACT_APP_API_SUGGESTIONS_LEMIEFOTO);
                    if (response.data) {
                        setSuggestionsCorporatura(aggiungiLabel(response.data.corporatura,t))
                        setSuggestionsAspetto(aggiungiLabel(response.data.aspetto,t))
                        setSuggestionsOcchi(aggiungiLabel(response.data.occhi,t))
                        setSuggestionsCapelli(aggiungiLabel(response.data.capelli,t))
                        setSuggestionsVesto(aggiungiLabel(response.data.vesto,t))
                        setSuggestionsVesto(aggiungiLabel(response.data.vesto,t))
                        setSuggestionsSegniparticolri(aggiungiLabel(response.data.segniparticolari,t))
                        setSuggestionsInteressi(aggiungiLabelInteressi(response.data.interessi,t))
                        setSuggestionsMireputo(aggiungiLabel(response.data.mireputo,t))
                    } else {
                    
                    }
                } catch (error) {
                    console.log("Error fetching data:", error);
                }
            };
            fetchSuggestions();
        }
    }

    const manageLeMieFotoData = () => {
        
        if (!leMieFotoData || Object.keys(leMieFotoData)?.length === 0) {
        
            const fetchData = async () => {
                try {
                    const response = await apiClient.get(
                    process.env.REACT_APP_API_USER_LEMIEFOTODATA);

                    if (response.data?.length > 0) {
                        setLeMieFotoData(aggiungiLabel(response.data[0],t))
                    } else {
                    
                    }
                } catch (error) {
                console.log("Error fetching data:", error);
                }
            };
            fetchData();
        }    

    }

    
    const updateField = async (fieldData) => {
        try {
            const response = await apiClient.post(
                process.env.REACT_APP_API_USER_UPDATEFIELD, fieldData
            );
            if (response.data) {
                updateLeMieFotoData(fieldData.campo, fieldData.newValue) 
            }
        } catch (error) {
            console.log("Error fetching data:", error);
            throw error; 
        }
    };

    const updateFieldMultiple = async (fieldData,newValue) => {
        console.log("GGGGG",fieldData)
        try {
            const response = await apiClient.post(
                process.env.REACT_APP_API_USER_UPDATEFIELD, fieldData
            );
            if (response.data) {
                updateLeMieFotoData(fieldData.campo, newValue)
            }
        } catch (error) {
            console.log("Error fetching data:", error);
            throw error; 
        }
    };

    const updateLeMieFotoData = (campo, valore) => {
        setLeMieFotoData({...leMieFotoData, [campo]:valore})
    };

    const groupedItemTemplateInteressi = (item) => {
        return (
            <div className="flex align-items-center">
                <div>{item.label}</div>
            </div>
        );
    };
    
    
    return (
        <>
            <div className="flex align-items-stretch flex-wrap" >
                {immagini}
            </div>
            <div className="formgrid grid">
                <div className="col-12 md:col-6 mb-4">
                    <MocFieldComp
                        id="alto" 
                        value={leMieFotoData?.alto} 
                        mode="decimal" 
                        showButtons={true}
                        sezione="lemiefoto"
                        type="inputnumber"
                        min={130} 
                        max={240}
                        label={t('alto')}
                        updateField={updateField}
                        maxLength={3}
                    />
                    
                </div>
                <div className="col-12 md:col-6 mb-4">
                    <MocFieldComp
                        id="peso" 
                        value={leMieFotoData?.peso} 
                        mode="decimal" 
                        showButtons={true}
                        sezione="lemiefoto"
                        type="inputnumber"
                        min={30} 
                        max={350}
                        label={t('peso')}
                        updateField={updateField}
                        maxLength={3}
                    />
                </div>
                <div className="col-12 md:col-6 mb-4">
                    <MocFieldComp 
                        id="occhi" 
                        field="label" 
                        value={leMieFotoData?.occhi} 
                        suggestions={leMieFotoSuggestions?.occhi} 
                        sezione="lemiefoto"
                        type="autocomplete"
                        updateField={updateField}
                        label={t('occhi')}
                    />
                </div>
                <div className="col-12 md:col-6 mb-4">
                    <MocFieldComp 
                        id="capelli" 
                        field="label" 
                        value={leMieFotoData?.capelli} 
                        suggestions={leMieFotoSuggestions?.capelli} 
                        sezione="lemiefoto"
                        type="autocomplete"
                        updateField={updateField}
                        label={t('capelli')}
                    />
                </div>                

                <div className="col-12 md:col-6 mb-4">
                    <MocFieldComp 
                        id="corporatura" 
                        field="label" 
                        value={leMieFotoData?.corporatura} 
                        suggestions={leMieFotoSuggestions?.corporatura} 
                        sezione="lemiefoto"
                        type="autocomplete"
                        updateField={updateField}
                        label={t('corporatura')}
                    />
                </div>

                <div className="col-12 md:col-6 mb-4">
                    <MocFieldComp 
                        id="aspetto" 
                        field="label" 
                        value={leMieFotoData?.aspetto} 
                        suggestions={leMieFotoSuggestions?.aspetto} 
                        sezione="lemiefoto"
                        type="autocomplete"
                        updateField={updateField}
                        label={t('aspetto')}
                    />
                </div>    

                <div className="col-12 md:col-6 mb-4">
                    <MocFieldComp 
                        id="vesto" 
                        field="label" 
                        value={leMieFotoData?.vesto} 
                        suggestions={leMieFotoSuggestions?.vesto} 
                        sezione="lemiefoto"
                        type="autocomplete"
                        updateField={updateField}
                        label={t('vesto')}
                    />
                </div> 

                <div className="col-12 md:col-6 mb-4">
                    <MocFieldComp 
                        id="segniparticolari" 
                        field="label" 
                        multiple={true}
                        value={leMieFotoData?.segniparticolari} 
                        suggestions={leMieFotoSuggestions?.segniparticolari} 
                        sezione="lemiefoto"
                        type="autocomplete"
                        updateField={updateFieldMultiple}
                        label={t('segniparticolari')}
                    />
                </div>

                <div className="col-12 md:col-6 mb-4">
                    <MocFieldComp 
                        id="interessi" 
                        field="label" 
                        multiple={true}
                        value={leMieFotoData?.interessi} 
                        suggestions={leMieFotoSuggestions?.interessi}
                        sezione="lemiefoto"
                        type="autocomplete"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        groupedItemTemplate={groupedItemTemplateInteressi}
                        updateField={updateFieldMultiple}
                        label={t('interessi')}
                    />
                </div>  

                <div className="col-12 md:col-6 mb-4">
                    <MocFieldComp 
                        id="mireputo" 
                        field="label" 
                        multiple={true}
                        value={leMieFotoData?.mireputo} 
                        suggestions={leMieFotoSuggestions?.mireputo} 
                        sezione="lemiefoto"
                        type="autocomplete"
                        updateField={updateFieldMultiple}
                        label={t('mireputo')}
                    />
                </div>                                            
                
            </div>
        </>
    );
}

export default LeMieFotoComp;