import React, { useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import ChiSonoComp from './ChiSonoComp';
import IMieiDatiPersonaliComp from './IMieiDatiPersonaliComp';
import LeMieFotoComp from './LeMieFotoComp';
import { useTranslation } from 'react-i18next';
import LaMiaStanzaSegretaComp from './LaMieStanzaSegretaComp';
import IlMioLuogoComp from './IlMioLuogoComp';

const IMieiDatiComp = (props) => {
    const { t } = useTranslation();


    useEffect(() => {
    }, []);

    return (
                
        <div>
            <TabView>
                <TabPanel header={t('ChiSono')}>
                    <ChiSonoComp/>
                </TabPanel>
                <TabPanel header={t('LeMieFoto')}>
                    <LeMieFotoComp/>
                </TabPanel>
                <TabPanel header={t('IMieiDatiPersonali')}>
                    <IMieiDatiPersonaliComp/>
                </TabPanel>
                <TabPanel header={t('MioLuogo')}>
                    <IlMioLuogoComp/>
                </TabPanel>  
                <TabPanel header={t('LaMiaStanzaSegreta')}>
                    <LaMiaStanzaSegretaComp />
                </TabPanel>                               
            </TabView>
        </div>    
         
    );
}

export default IMieiDatiComp;