import 'leaflet/dist/leaflet.css';
import '../../assets/styles/components/imieidati/IlMioLuogoComp.less';
import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents  } from 'react-leaflet';
import L from 'leaflet';
import { useSuggestionsIlMioLuogo } from '../../store';
import { useImieiDatiStore } from '../../store';
import useApiClientInterceptors from '../../useApiClientInterceptors';
import { useTranslation } from 'react-i18next';
import { aggiungiLabel } from '../../utils';
import { Button } from 'primereact/button';
import MocFieldComp from '../common/MocFieldComp';

const IlMioLuogoComp = () => {

    const { ilMioLuogoData, setIlMioLuogoData } = useImieiDatiStore();
    const semiTransparentIcon = new L.Icon({
        iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
        iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
        shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
        className: ilMioLuogoData?.status== "dirty" ? 'semi-transparent-marker' : "",
    });
    const { ilMioLuogoSuggestions, setSuggestionsTipoLuogo } = useSuggestionsIlMioLuogo();
    const apiClient = useApiClientInterceptors();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const manageIlMioLuogoSuggestions = () => {
            if (ilMioLuogoSuggestions?.tipoluogo?.length === 0) {
              const fetchSuggestions = async () => {
                try {
                  const response = await apiClient.get(process.env.REACT_APP_API_SUGGESTIONS_ILMIOLUOGO);
                  if (response.data) {
                    setSuggestionsTipoLuogo(aggiungiLabel(response.data.tipoluogo, t));
                  }
                } catch (error) {
                  console.error("Error fetching suggestions:", error);
                }
              };
              fetchSuggestions();
            }
        };
        manageIlMioLuogoSuggestions()

        const manageIlMioLuogoData = () => {
            if (ilMioLuogoData.status === "init" | ilMioLuogoData.status === "dirty") {
                console.log("ZZZZ useEffect")
                setLoading(true)
                const fetchData = async () => {
                try {
                    const response = await apiClient.get(process.env.REACT_APP_API_USER_ILMIOLUOGODATA);
                    if (response.data?.length > 0) {
                        console.log("ZZZZ chiamata api completata")
                        const { tipoluogo, markerpos, center, zoom } = response.data[0];
                        setIlMioLuogoData({ tipoluogo: { id: tipoluogo, label: t(tipoluogo) }, center: center, markerpos: markerpos, zoom: zoom, status: "saved" });
                    } 
                    setLoading(false);
                    } catch (error) {
                        console.error("Error fetching data:", error);
                        setLoading(false);
                    }
                };
                fetchData();
            } 
        };
        manageIlMioLuogoData()
    
    }, [])
    
    const MapEvents = () => {
        useMapEvents({
            moveend: (event) => {
                console.log('ZZZZ Movimento della mappa terminato', event.target.getCenter());
                const map = event.target;
                const newcenter = map.getCenter();
                const newzoom = map.getZoom();
                setIlMioLuogoData({ ...ilMioLuogoData, center: [newcenter.lat, newcenter.lng], zoom: newzoom, status:"dirty" });
            }
        });
        return null;
    };
    
    if (loading) {
        return <p>Caricamento in corso...</p>;
    }

    const handleMarkerDragEnd = (event) => {
        const newPosition = event.target.getLatLng()
        console.log(`ZZZZ marker spostato ${newPosition}`);
        setIlMioLuogoData({ ...ilMioLuogoData, markerpos: [newPosition.lat, newPosition.lng], status:"dirty" });
    }

    const updateField = async (fieldData) => {
        try {
          const response = await apiClient.post(process.env.REACT_APP_API_USER_UPDATEFIELD, fieldData);
          if (response.data) {
            setIlMioLuogoData({ ...ilMioLuogoData, tipoluogo: { id: fieldData.newValue, label: t(fieldData.newValue) } });
          }
        } catch (error) {
          console.error("Error updating data:", error);
        }
      };

    return (
        <>
        <div className="info-message">
        <i className="pi pi-info-circle info-message-icon"></i>
        <div className="info-message-text">
            {t('mapMessaggioLine1')}<br />
            <span className="text-with-dropdown mt-1">
            <span className="text">{t('mapMessaggioLine2')}</span>
            <MocFieldComp
                id="tipoluogo"
                value={ilMioLuogoData?.tipoluogo}
                sezione="mioluogo"
                options={ilMioLuogoSuggestions?.tipoluogo}
                type="dropdown"
                updateField={updateField}
                label="label"
            />
            </span>
        </div>
        </div>
        <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
        <MapContainer 
            center={ilMioLuogoData.center} 
            zoom={ilMioLuogoData.zoom} 
            style={{ height: "100vh", width: "100%" }}
            >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
                position={ilMioLuogoData.markerpos}
                draggable={true}
                icon={semiTransparentIcon}
                eventHandlers={{
                    dragend: handleMarkerDragEnd,
                }}
                >
                <Popup>
                    Roma, Italia. <br /> Una città storica e affascinante.
                </Popup>
            </Marker>
            <MapEvents />
        </MapContainer>
        {ilMioLuogoData?.status === "dirty" && <Button
            icon="pi pi-check"
            className="p-button-rounded p-button-success"
            style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: 1000,
            }}

            onClick={async () => {  // Rendi la funzione async
                console.log("ZZZZ");
            
                try {
                    const response = await apiClient.post( // Usa await per aspettare la risposta
                        process.env.REACT_APP_API_USER_SALVALUOGOMAP,
                        ilMioLuogoData
                    );
            
                    console.log("ZZZZ1 ", JSON.stringify(response.data));
            
                    if (response.data) {
                        console.log("ZZZZ2 ", JSON.stringify(response.data));
                        setIlMioLuogoData({ ...ilMioLuogoData, status: "saved" });
                    }
                } catch (error) {
                    console.error("Error saving place:", error);
                }
            }}


        />}
        </div>
        </>
    )

}

export default IlMioLuogoComp;