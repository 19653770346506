
import React, { useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { aggiungiLabel } from '../../utils'
import useApiClientInterceptors  from '../../useApiClientInterceptors';
import { useSuggestionsIMieiDatiPersonali } from '../../store';
import { useImieiDatiStore } from '../../store';
import MocFieldComp from '../common/MocFieldComp';

const IMieiDatiPersonaliComp = () => {
  
  const { t } = useTranslation();
  const apiClient = useApiClientInterceptors();
  const { iMieiDatiPersonaliSuggestions, setSuggestionsSitsentimentale, setSuggestionsFigli, setSuggestionsBevo, setSuggestionsFumo,
    setSuggestionsQuiper, setSuggestionsLavoro, setSuggestionsSiteconomica, setSuggestionsVorreifigli }  = useSuggestionsIMieiDatiPersonali()
  const { iMieiDatiPersonaliData, setIMieiDatiPersonaliData }  = useImieiDatiStore()

  useEffect(() => {

    manageIMieiDatiPersonaliSuggestions()
    manageImieiDatiPersonaliData()
    
  }, []);

  const manageIMieiDatiPersonaliSuggestions = () => {


    if (iMieiDatiPersonaliSuggestions?.quiper?.length === 0){


        const fetchSuggestions = async () => {

            try {
                const response = await apiClient.get(
                process.env.REACT_APP_API_SUGGESTIONS_IMIEIDATIPERSONALI);
                if (response.data) {
                  setSuggestionsSitsentimentale(aggiungiLabel(response.data.sitsentimentale,t,[]))
                  setSuggestionsFigli(aggiungiLabel(response.data.figli,t,[]))
                  setSuggestionsBevo(aggiungiLabel(response.data.bevo,t,[]))
                  setSuggestionsFumo(aggiungiLabel(response.data.fumo,t,[]))
                  setSuggestionsQuiper(aggiungiLabel(response.data.quiper,t,[]))
                  setSuggestionsLavoro(aggiungiLabel(response.data.lavoro,t,[]))
                  setSuggestionsSiteconomica(aggiungiLabel(response.data.siteconomica,t,[]))
                  setSuggestionsVorreifigli(aggiungiLabel(response.data.vorreifigli,t,[]))
                } else {
                
                }
            } catch (error) {
                console.log("Error fetching data:", error);
            }
        };
        fetchSuggestions();
    }
  }

  const updateField = async (fieldData) => {
    try {
        const response = await apiClient.post(
            process.env.REACT_APP_API_USER_UPDATEFIELD, fieldData
        );
        if (response.data) {
          updateIMieiDatiPersonaliData(fieldData.campo, fieldData.newValue) 
        }
    } catch (error) {
        console.log("Error fetching data:", error);
        throw error; 
    }
  };


  const updateIMieiDatiPersonaliData = (campo, valore) => {
      setIMieiDatiPersonaliData({...iMieiDatiPersonaliData, [campo]:valore})
  };


  const manageImieiDatiPersonaliData = () => {
        
    if (!iMieiDatiPersonaliData || Object.keys(iMieiDatiPersonaliData)?.length === 0) {
    
        const fetchData = async () => {
            try {
                const response = await apiClient.get(
                process.env.REACT_APP_API_USER_IMIEIDATIPERSONALIDATA);

                if (response.data?.length > 0) {
                  setIMieiDatiPersonaliData(aggiungiLabel(response.data[0],t,[]))
                } else {
                
                }
            } catch (error) {
            console.log("Error fetching data:", error);
            }
        };
        fetchData();
    }    

}

  return (
    <>
    <div className="mt-6 formgrid grid">
        <div className="col-12 md:col-6 mb-4">
          <MocFieldComp 
                id="quiper" 
                field="label" 
                value={iMieiDatiPersonaliData?.quiper} 
                suggestions={iMieiDatiPersonaliSuggestions?.quiper} 
                sezione="datipersonali"
                type="autocomplete"
                updateField={updateField}
                label={t('quiper')}
          />
        </div>   
        <div className="col-12 md:col-6 mb-4">
          <MocFieldComp 
                id="sitsentimentale" 
                field="label" 
                value={iMieiDatiPersonaliData?.sitsentimentale} 
                suggestions={iMieiDatiPersonaliSuggestions?.sitsentimentale} 
                sezione="datipersonali"
                type="autocomplete"
                updateField={updateField}
                label={t('sitsentimentale')}
          />
        </div>
        <div className="col-12 md:col-6 mb-4">
          <MocFieldComp 
                id="figli" 
                field="label" 
                value={iMieiDatiPersonaliData?.figli} 
                suggestions={iMieiDatiPersonaliSuggestions?.figli} 
                sezione="datipersonali"
                type="autocomplete"
                updateField={updateField}
                label={t('figli')}
          />
        </div>    
        <div className="col-12 md:col-6 mb-4">
          <MocFieldComp 
                id="vorreifigli" 
                field="label" 
                value={iMieiDatiPersonaliData?.vorreifigli} 
                suggestions={iMieiDatiPersonaliSuggestions?.vorreifigli} 
                sezione="datipersonali"
                type="autocomplete"
                updateField={updateField}
                label={t('vorreifigli')}
          />
        </div>
        <div className="col-12 md:col-6 mb-4">
          <MocFieldComp 
                id="lavoro" 
                field="label" 
                value={iMieiDatiPersonaliData?.lavoro} 
                suggestions={iMieiDatiPersonaliSuggestions?.lavoro} 
                sezione="datipersonali"
                type="autocomplete"
                updateField={updateField}
                label={t('lavoro')}
          />
        </div>
        <div className="col-12 md:col-6 mb-4">
          <MocFieldComp 
                id="siteconomica" 
                field="label" 
                value={iMieiDatiPersonaliData?.siteconomica} 
                suggestions={iMieiDatiPersonaliSuggestions?.siteconomica} 
                sezione="datipersonali"
                type="autocomplete"
                updateField={updateField}
                label={t('siteconomica')}
          />
        </div>
        <div className="col-12 md:col-6 mb-4">
          <MocFieldComp 
                id="fumo" 
                field="label" 
                value={iMieiDatiPersonaliData?.fumo} 
                suggestions={iMieiDatiPersonaliSuggestions?.fumo} 
                sezione="datipersonali"
                type="autocomplete"
                updateField={updateField}
                label={t('fumo')}
          />
        </div> 
        <div className="col-12 md:col-6 mb-4">
          <MocFieldComp 
                id="bevo" 
                field="label" 
                value={iMieiDatiPersonaliData?.bevo} 
                suggestions={iMieiDatiPersonaliSuggestions?.bevo} 
                sezione="datipersonali"
                type="autocomplete"
                updateField={updateField}
                label={t('bevo')}
          />
        </div>                   
      </div>
    </>
  );
}

export default IMieiDatiPersonaliComp;
