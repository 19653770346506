
import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const Spinner = ({ visible }) => {
  return (
    <>
    { visible  && <div style={{ display: visible ? "block" : "none", zIndex:999, position: 'absolute', top: '50%', left: '50%',  background: 'rgba(0, 0, 0, 0)' }}>
        <ProgressSpinner />
    </div> }
    </>
  );
}

export default Spinner;
